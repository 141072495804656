@import 'leaflet/dist/leaflet.css';
@import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';

.animate-colors {
  transition: all 0.5s ease;
  transition-property: fill, color;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: 0s;
}

.map-frame {
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px 0;
}

assist-new-resource-map {
  display: block;
  height: 40vh;

  .map-container {
    padding-top: 30px;
    height: 100%;
    margin: 0;
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }

  .leaflet-container {
    background: white;
    outline: 0;
  }

  #map {
    height: 100%;
  }

  .leaflet-popup {
    margin-bottom: 0;
    .leaflet-popup-tip-container {
      display: none;
    }
    .leaflet-popup-close-button {
      display: none;
    }
    .leaflet-popup-content-wrapper {
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
    }
  }
}
